<template>
  <div class="app-view">

    <div class="questions-breadcrumb">
      <span @click="resetCat"><img src="@/assets/stroetmann-lupe.svg" /></span>
      <span v-if="this.chosenCat" @click="resetCat">{{ this.chosenCat }}</span>
      <span v-for="(quizAnswer, answerid) in this.quizAnswers" :key="quizAnswer" @click="resetAnswers(answerid)"><span class="delimitor"></span> {{ quizAnswer }}</span>
      <span>&nbsp;</span>
    </div>
    <div v-if="this.chosenCats.length > 0 && this.chosenCat == ''" class="question-wrapper">
      <div class="question-title">Suchen Sie eine Grünland- oder eine Ackerfutter-Mischung?</div>
      <div class="possible-answers">
        <div class="possible-answer" v-for="sheetName in chosenCats" :key="sheetName">
          <input type="checkbox" v-model="this.chosenCat" :true-value="sheetName" false-value="" :key="sheetName" :id="'answer-' + sheetName" :value="sheetName" @change="scrollBreadcrumb($event)" /> <label :for="'answer-' + sheetName">{{ sheetName }}</label>
        </div>
      </div>
      <!-- <input type="radio" v-model="this.chosenCat" v-for="sheetName in chosenCats" :key="sheetName" :value="sheetName" @change="scrollBreadcrumb" /><span></span> -->
      <!-- <select v-model="this.chosenCat" @change="scrollBreadcrumb">
          <option v-for="sheetName in chosenCats" :key="sheetName" :value="sheetName">{{sheetName}}</option>
      </select> -->
    </div>
    <div v-else-if="this.chosenCat !== '' && quiz[this.chosenCat].sets[quizAnswers.length] && chosenCatProds.length > 1" class="question-wrapper">
      <div class="question-title">{{ quiz[this.chosenCat].sets[quizAnswers.length].Frage }}</div>
      <div class="possible-answers">
        <div class="possible-answer" v-for="antwort in filterAllowed(quiz[this.chosenCat].sets[quizAnswers.length].Antworten)" :key="antwort">
          <input type="checkbox" v-model="quizAnswers[quizAnswers.length]" :true-value="antwort" false-value="" :key="antwort" :id="'answer-' + antwort" :value="antwort" @change="scrollBreadcrumb" /> <label :for="'answer-' + antwort">{{ antwort }}</label>
        </div>
      </div>
      <!-- <div>Möglichkeiten:</div>
      <select v-model="quizAnswers[quizAnswers.length]" @change="scrollBreadcrumb">
        <option v-for="antwort in quiz[this.chosenCat].sets[quizAnswers.length].Antworten" :key="antwort" :value="antwort">{{ antwort }}</option>
      </select> -->
    </div>
    <div class="results-outer-wrapper" v-else>
      <!-- <div v-if="chosenCatProds.length > 0">Ihre Ergebnisse:</div>
      <div v-else>Keine Ergebnisse gefunden.</div>
      <ul v-if="chosenCatProds.length > 0">
        <li v-for="prod in chosenCatProds" :key="prod">{{ prod.Name }}</li>
      </ul> -->
      <div class="results-compared-props">
        <div>Sorte</div>
      </div>
      <div class="results-inner-wrapper">




        <div class="single-result" v-for="prod in chosenCatProds" :key="prod.Name" @click="toggleActive($event)">
          <div class="result-compare-property">
            <div class="result-properties">
              <div class="result-property" v-for="prop in chosenCatProps" :key="prop" v-show="prop !== 'Name' && prop !== 'PDF' && prod[prop] !== ''">
                <div class="result-property-title">{{ prop }}:</div>
                <div class="result-property-value" v-if="prod[prop] !== ''">{{ prod[prop] }}</div>
                <div class="result-property-value" v-else>-</div>
              </div>
              <div class="result-property" v-if="prod['PDF'] !== '' && typeof prod['PDF'] !== 'undefined'">
                <div class="result-property-title">Sortenblatt:</div>
                <div class="result-property-value sortenblatt">Sortenblatt ansehen: <a :href="`https://stroetmann-saat.de/fileadmin/user_upload/PDF-Karten_Sortenfinder/${prod['PDF']}`" target="_blank"><img src="@/assets/pdf-icon.svg"></a></div>
              </div>
            </div>
            <div class="property-value">{{ prod.Name }}</div>
          </div>
        </div>
      <div v-if="chosenCatProds.length == 0" class="no-result">Keine Ergebnisse gefunden.</div>





        <!-- <div class="single-result" v-for="prod in chosenCatProds" :key="prod.Name" :class="{ active: prod.active }" @click="toggleActive(prod)">
          <div class="result-compare-property" v-for="feld in compareFelder" :key="feld" >
            <div class="result-properties" v-if="feld.Key === 'Name'">
              <div v-for="(feld, feldK) in felder" :key="feld.Key" class="result-property">
                <div class="property-key" v-if="feld.Key !== 'Name'">{{ feld.Bezeichnung }}</div>
                <div class="property-value" v-if="feld.Key !== 'Name'">
                  <img v-if="feld.Bild == 1 && prod.Felder[feld.Key] !== '' && prod.Felder[feld.Key] !== '0'" :src="`https://portal.stroetmann.de/kupoweb/assets/sorteninfo/${feld.Key}_${thread.Felder[feld.Key]}.gif`" :alt="thread.Felder[feldK]" />
                  <span v-else-if="prod.Felder[feld.Key] && prod.Felder[feld.Key] !== '' && prod.Felder[feld.Key] !== '0'">{{ prod.Felder[feld.Key] }}</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss">

body { margin: 0; }

.questions-breadcrumb {
  background-color: #FFFFFF;
  box-sizing: border-box;
  color: #56BAA3;
  font-size: 14px; line-height: 22px;
  padding: 10px 20px; padding-left: 48px;
  position: relative;
  text-align: left;
  overflow-x: scroll; overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  // max-width: 414px;
  &::-webkit-scrollbar { width: 0; height:0; }
  scrollbar-width: none;  /* Firefox */
  // &::before {
  //   content: '\1F50D'; line-height: 22px; display: block; position: absolute; top: 10px; left: 12px; 
  // }
  > span {
    cursor: pointer;
    display: inline-block;
    &:first-child {
      position: absolute; top: 12px; left: 20px;
      img {
        display: block;
      }
    }
    .delimitor {
      padding-left: 18px; padding-right: 18px;
      position: relative;
      &::before {
        content: '';
        border-color: #56BAA3 #56BAA3 transparent transparent;
        border-width: 2px 2px 0 0;
        border-style: solid;
        display: inline-block;
        padding: 3px;
        position: absolute; top: 1px; left: 50%;
        margin-right: 8px;
        -webkit-transform: translateY(3px) rotate(45deg);
        -moz-transform: translateY(3px) rotate(45deg);
        -ms-transform: translateY(3px) rotate(45deg);
        -o-transform: translateY(3px) rotate(45deg);
        transform: translateY(3px) rotate(45deg);
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        -o-transform-origin: top;
        transform-origin: top;
        -webkit-backface-visibility: hidden
      }
    }
  }
}

.question-wrapper {
  background-color: #56BAA3;
  box-sizing: border-box;
  color: #fff;
  margin: 0 auto;
  // max-width: 640px;
  // height: 100%;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 30px 0;
  text-align: left;
  width: 100%;
  &::-webkit-scrollbar { width: 0; }
  .question-title {
    font-size: 24px; font-weight: 700; line-height: 32px;
    padding: 0 20px;
  }
  .possible-answers {
    margin-top: 54px;
    .possible-answer {
      border-top: 1px solid #ffffff;
      cursor: pointer;
      display: block;
      font-size: 24px; line-height: 1;
      position: relative;
      &:last-child { 
        border-bottom: 1px solid #ffffff;
      }
        
      input[type=checkbox]{
        cursor: pointer;
        margin: 0;
        position: absolute; top: 0; left: 0;
        width: 100%; height: 100%;
        --webkit-appearance: none;
        appearance: none;
        // &:checked{
          & + label {
            // background-color: #FFFFFF;
            // color: #56BAA3;
            background-color: #56BAA3;
            color: #FFFFFF;
            transition: all .3s ease-in-out;
            font-size: 24px; line-height: 1;
            
            cursor: pointer;
            display: block;
            padding: 12px 20px; padding-left: 64px;
            position: relative;
            user-select: none;
            z-index: 1;
            &::before {
              border: 1px solid #FFFFFF;
              border-radius: 3px;
              content: '';
              width: 28px; height: 28px;
              display: inline-block;
              position: absolute; top: 9px; left: 24px;

            }
            &:hover {
              background-color: #FFFFFF;
              color: #56BAA3;
              &::before {
                background-color: #56BAA3;
              }
              &::after {
                border-top: 2px solid #FFFFFF; border-right: 2px solid #FFFFFF; border-bottom: 0; border-left: 0;
                content: '';
                display: inline-block;
                padding-top: 6px; padding-right: 12px;
                position: absolute; top: 18px; left: 32px;
                transform: rotate(135deg);
              }
            }
          }
          &:checked + label {
            background-color: #FFFFFF;
            color: #56BAA3;
              &::before {
                background-color: #56BAA3;
              }
            &::after {
              border-top: 2px solid #FFFFFF; border-right: 2px solid #FFFFFF; border-bottom: 0; border-left: 0;
              content: '';
              display: inline-block;
              padding-top: 6px; padding-right: 12px;
              position: absolute; top: 21px; left: 32px;
              transform: rotate(135deg);
            }
          }
          &:disabled {
            + label {
              background-color: #56BAA3;
              color: #FFFFFF;
              cursor: default;
              filter: grayscale(1);
              &::after {
                content: none !important;
              }
            }
          }
        // }
        &:hover::after, &:check
        &:focus { outline: none; }
      }
    }
  }
}

.results-outer-wrapper {
  background-color: #FFFFFF;
  margin: 0 auto;
  // max-width: 640px;
  height: 100%;
  flex: 1;
  overflow: scroll;
  width: 100%;
  &::-webkit-scrollbar { width: 0; height: 0; }
  .results-compared-props {
    background-color: #58645A;
    color: #ffffff;
    display: flex;
    font-size: 12px; line-height: 12px;
    position: relative;
    text-align: left;
    user-select: none;
    &::before {
      // content: '';
      display: block;
      height: 1px;
      background-color: #ffffff;
      position: absolute; bottom: 0; left: 0; right: 0;
      padding: 9px 12px;
    }
    > div {
      border-bottom: 1px solid #ffffff;
      flex: 0 0 calc(100% / 3 - 24px);
      padding: 9px 12px;
      position: relative;
      &::before {
        background-color: #ffffff;
        content: '';
        display: block;
        position: absolute; top: 0; left: 0; bottom: 0;
        width: 1px;
      }
      &:first-child {
        flex: 0 0 calc(200% / 3 - 24px);
        &::before { content: none; }
      }
      &:first-child:last-child {
        flex-basis: calc(100% - 24px);
      }
      &:last-child {

      }
      &[data-sort="ASC"]{
        &::after {
          // content: '\2191';
        }
      }
      &[data-sort="DESC"]{
        &::after {
          // content: '\2193';
        }
      }
    }
  }
  .results-inner-wrapper {
    .single-result {
      color: #FFF;
      cursor: pointer;
      display: flex;
      margin-bottom: 1px;
      position: relative;
      user-select: none;
      .result-title {
        background-color: #58645A;
        padding: 9px 12px;
        text-align: left;
        flex: 0 0 calc(200% / 3 - 24px);
      }
      .result-properties {
          background-color: #ffffff;
          position: absolute; top: 100%; left: 0;
          padding-left: 9px;
          width: 150%; height: 0;
          z-index: 1;
          overflow: hidden;
          transition: height .3s ease-in-out;
        .result-property {
          background-color: #E8F6F5;
          color: #1F1F1F;
          // display: flex;
          text-align: left;
          .result-property-title {
            background-color: #FFFFFF;
            border-bottom: 1px solid #DEDEDE;
            color: #B5B5B5;
            font-size: 16px; font-weight: 700; line-height: 24px;
            padding: 3px 20px; 
            text-transform: uppercase;
          }
          .result-property-value {
            border-bottom: 1px solid #DEDEDE;
            padding: 3px 20px;
            &.sortenblatt {
              display: flex; align-items: center;
              a { padding-left: 32px; }
            }
          }
          .property-key {
            flex: 0 0 60%; 
            padding: 9px 12px;
          }
          .property-value {
            // flex: 0 0 35%;
            font-weight: 700;
            padding: 9px 12px;
            width: 100%;
            word-break: break-all;
            img {
              position: relative;
              display: block;
              width: auto;
              height: 32px;
              &::after {
                content: attr(alt);
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                min-height: 100%;
                height: auto;
                width: 100%;
                background-color: #E8F6F5;
                z-index: 1;
              }
            }
          }
        }
        .result-compare-property {
          flex: 0 0 calc(100% / 3 - 24px);
        }
      }
      .result-compare-property {
        &:first-child {
          background-color: #58645A;
        }
      }
      &.active {
        z-index: 10;
        &::before {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          border-color: transparent #003a2d transparent transparent;
          border-style: solid;
          border-width: 0 9px 9px 0;
          width: 0;
          height: 0;
          z-index: 10;
        }
        .result-title {
          background-color: #007858;
        }
        // .result-properties {
        //   background-color: #ffffff;
        //   position: absolute; top: 100%; left: 0;
        //   padding-left: 9px;
        //   width: 150%;
        //   z-index: 1;
        //   overflow: hidden;
        // }
        .result-compare-property {
          &:first-child {
            background-color: #007858;
            &::after {
              background-image: url('/img/icons/icon_single_result_less.svg')
            }
          }
        }
      }
      .result-compare-property {
        box-sizing: border-box;
        cursor: pointer;
        flex: 0 0 calc(100% / 3);
        padding: 9px 12px; padding-right: 40px;
        pointer-events: none;
        text-align: center;
        a {
          pointer-events: all;
          img {
            width: auto; height: 32px;
          }
        }
        &:first-child {
          flex: 0 0 calc(200% / 3);
          text-align: left;
          position: relative;
          &::after {
            background-image: url('/img/icons/icon_single_result_more.svg');
            background-position: top center; background-repeat: no-repeat; background-size: contain;
            content: '';
            display: block;
            position: absolute; top: 8px; right: 8px;
            transform: translateX(-50%);
            width: 19px; height: 19px;
          }
          // position: sticky; left: 0;
        }
        &:first-child:last-child {
          .result-properties { width: calc(100% - 9px); }
          //only "Sorte"
          flex-basis: calc(100%)

        }
      }
    }
    .no-result {
      background-color: #58645A;
      color: #fff;
      padding: 9px 12px;
      text-align: left;
    }
  }
}
</style>

<script>
import lodash from 'lodash'
import axios from 'axios'
import XLSX from 'xlsx'

export default {
  name: 'Home',
  data(){
    return {
      extendFilter: true, //eigentlich sind manche Filter versteckt
      searchTerm: '',
      selfFilterFelder: {},
      quizWorkbook: null,
      quiz: null,
      quizAnswers: [],
      chosenCat: ''
      // subset: []
    }
  },
  methods: {

    previewStaticFiles() {
      
        axios.request({
          // url: 'assets/mischungsfinder_2021_04_16.xlsx',
          url: 'assets/mischungsfinder_2021_06_14.xlsx',
          method: 'GET',
          responseType: 'arraybuffer'
        })
        .then(response => {
          
          this.quizWorkbook = XLSX.read(response.data, {type:"array"});
          const sheetName = this.quizWorkbook.SheetNames[0] //Fragebogenliste
          /* DO SOMETHING WITH workbook HERE */
          // console.log(workbook);
          const fragebogen = this.quizWorkbook.Sheets[sheetName];
          const fragebogenjson = XLSX.utils.sheet_to_json(fragebogen)
          // delete fragebogenjson[0]["Kategorie"]
          const kategorien = Object.keys(fragebogenjson[0]).splice(1) //["Grünlandmischung", "Ackerfuttermischung", "Zwischenfruchtmischungen"] => Möglichkeiten für die 1. Frage
          
          const quiz = {}; //Objekt aus Frage-Antwort-Arrays
          kategorien.forEach(function(kategorie){
            quiz[kategorie] = {}
            quiz[kategorie]["productSheet"] = fragebogenjson[0][kategorie]
            quiz[kategorie]["sets"] = []
            for(let i=1; i<fragebogenjson.length - 1; i = i+2){
              if(typeof fragebogenjson[i][kategorie] !== "undefined"){
                const tempObj = {}
                tempObj["Frage"] = fragebogenjson[i][kategorie]
                tempObj["Antworten"] = fragebogenjson[i+1][kategorie].split(";;")
                quiz[kategorie]["sets"].push(tempObj);
              }
            }
          })
          // console.log(quiz)
          this.quiz = quiz
        })
    },
    resetAnswers(delimiter){
      this.quizAnswers.splice(delimiter)
      document.querySelector('.questions-breadcrumb').scrollLeft = 99999
    },
    resetCat(){
      this.chosenCat = ''
      this.quizAnswers= []
      document.querySelector('.questions-breadcrumb').scrollLeft = 99999
    },
    scrollBreadcrumb(event){
      // console.log(event.target);
      window.setTimeout(function(){
        document.querySelector('.questions-breadcrumb').scrollLeft = 99999
      }, 1)
    },
    toggleExtend(){
      this.extendFilter = !this.extendFilter
      // console.log(this.subset);
    },
    toggleActive(event){
      // console.log(event)
      if(event.target.classList.contains('active')){
        event.target.children[0].children[0].style.height = "0px"
        setTimeout(function(){
          event.target.classList.remove('active')
        }, 300)
      }
      else {
        document.querySelectorAll('.single-result').forEach(function(singRes){
          singRes.children[0].children[0].style.height = "0px"
          setTimeout(function(){
            singRes.classList.remove('active')
          }, 300)
        })
        event.target.children[0].children[0].style.height = event.target.children[0].children[0].scrollHeight + "px";
        setTimeout(function(){
          event.target.classList.add('active')
        }, 300)
      }
    },
    filterResults(event){
      // console.log("filterResults trigger");
      //set min/max for changed filter
      const filterInputs = document.querySelectorAll('[data-filter="' + event.target.dataset.filter + '"]')
      if(filterInputs.length > 1){
        if(event.target == filterInputs[0]){
          if(parseInt(filterInputs[0].value) >= parseInt(filterInputs[1].value)){
            filterInputs[0].value = Math.max(parseInt(filterInputs[1].value) - 1, parseInt(filterInputs[0].dataset.init))
          }
          else if(parseInt(filterInputs[0].value) < parseInt(filterInputs[0].dataset.init)){
            filterInputs[0].value = filterInputs[0].dataset.init
          }
        }
        else if(event.target == filterInputs[1]){
          if(parseInt(filterInputs[1].value) <= parseInt(filterInputs[0].value)){
            filterInputs[1].value = Math.min(parseInt(filterInputs[0].value) + 1, parseInt(filterInputs[1].dataset.init))
            // console.log(filterInputs[1].value)
          }
          else if(parseInt(filterInputs[1].value) > parseInt(filterInputs[1].dataset.init)){
            filterInputs[1].value = filterInputs[1].dataset.init
          }
        }
        // filterInputs[0].setAttribute('max', Math.max(parseInt(filterInputs[1].value) - 1, filterInputs[0].dataset.init))

        // filterInputs[1].setAttribute('min', Math.min(parseInt(filterInputs[0].value) + 1, parseInt(filterInputs[1].dataset.init)))
      }

      //Filter
      const selfFilterFelder = this.felder
      Object.values(this.felder).forEach(function(filterFeld){
        // if(filterFeld.comparing) console.log(filterFeld)
        document.querySelectorAll('[data-filter="' + filterFeld.Key+ '"]').forEach(function(inputfield, idx){
          if(typeof inputfield.dataset.init !== 'undefined'){
            //input fields
            if(inputfield.dataset.init !== inputfield.value){
              selfFilterFelder[filterFeld.Key].selectedFilter[idx] = inputfield.value
              // this.filterFelder[filterFeld.Key][idx] = inputfield.value
            }
          }
          else {
            //select
            if(inputfield.value !== '') selfFilterFelder[filterFeld.Key].selectedFilter[idx] = inputfield.value
            else selfFilterFelder[filterFeld.Key].selectedFilter[idx] = ''
          }
        })
      })
      // console.log(selfFilterFelder);
      this.selfFilterFelder = selfFilterFelder
      // return
    },
    sortSubset(event){

      //if target is already used for current sort, reverse order, else set new target
      const eventSort = event.target.dataset.sort
        document.querySelectorAll('.results-compared-props [data-key]').forEach(function(el){
          delete el.dataset.sort
        })
      if(eventSort == 'ASC') event.target.dataset.sort = 'DESC'
      else event.target.dataset.sort = 'ASC'
      const newSorting = { Key: event.target.dataset.key, order: event.target.dataset.sort }
      this.sorting = newSorting
    },
    switchFilter(event){
      const targetId = event.target.dataset.target
      const targetEl = document.getElementById(targetId)
      const targetActive = targetEl.dataset.active
      document.querySelectorAll('.filter-container').forEach(function(filCon){
        filCon.dataset.active = false;
      })
      targetEl.dataset.active = (targetActive == true || targetActive == "true") ? false : true;
      if(targetActive == true || targetActive == "true") event.target.blur()
    },
    filterAllowed(antworten){
      const retAntworten = []
      const numberOfAnswers = this.quizAnswers.length + 1
      antworten.forEach(antwort => {
        if(this.chosenCatProds.find(function(prod){
          if(prod["Antwort " + numberOfAnswers] == antwort) return prod
        }) || antwort=="egal") retAntworten.push(antwort)
      })
      return retAntworten
    }
  },
  computed: {
    threadId: {
      get() {
        return this.$store.state.threadList.Key
      },
      set(id){
        this.$store.dispatch('updateThread', id)
        this.$store.dispatch('resetFelder', id)
        // this.$store.dispatch('resetFelder', id).then(() => {
          this.selfFilterFelder = {}
        // })
        this.extendFilter = true //eigentlich sind manche Filter versteckt
      }
    },
    threadList: {
      get(){
        return this.$store.state.threadList.list
      }
    },
    threadListSubset: {
      get(){
        const selfFF = this.selfFilterFelder
        // console.log(Object.values(selfFF))
        let subset = this.threadList

        //filter by search term if set
        if(this.searchTerm !== '') subset = subset.filter(thread => Object.values(thread.Felder).some(val => val.toLowerCase().includes(this.searchTerm)))

        //check for applied filters
        Object.values(selfFF).forEach(function(el){
          switch(el.Filter){
            case 2: //select
              subset = subset.filter(function(thread){
                if(typeof el.selectedFilter !== 'undefined' && el.selectedFilter[0] !== '' && thread.Felder[el.Key] !== el.selectedFilter[0]) return false
                return thread
              })
            
            break

            case 1: //input min/max
              subset = subset.filter(function(thread){
                if(typeof el.selectedFilter[0] !== 'undefined' && parseInt(thread.Felder[el.Key]) < parseInt(el.selectedFilter[0])) return false
                if(typeof el.selectedFilter[1] !== 'undefined' && parseInt(thread.Felder[el.Key]) > parseInt(el.selectedFilter[1])) return false
                return thread
              })

            break

          }
        })

        // console.log(subset)
        if(this.sorting.Key !== ""){
          // console.log('↓');
          const currSorting = this.sorting
          switch(currSorting.order){
            case 'ASC':
              subset.sort(function(a, b){
                if(typeof a.Felder[currSorting.Key] === 'undefined') return -1;
                if(typeof b.Felder[currSorting.Key] === 'undefined') return 1;
                return (a.Felder[currSorting.Key] > b.Felder[currSorting.Key]) ? 1 : -1
              })
              break;

            case 'DESC':
              subset.sort(function(a, b){
                if(typeof a.Felder[currSorting.Key] === 'undefined') return 1;
                if(typeof b.Felder[currSorting.Key] === 'undefined') return -1;
                return (a.Felder[currSorting.Key] < b.Felder[currSorting.Key]) ? 1 : -1
              })
            break;
          }
          // console.log(subset)
          // subset.forEach(function(singleSet){
          //   console.log(singleSet.Felder[currSorting.Key])
          // })
        }
        return subset
      }
    },
    artList: {
      get(){
        return this.$store.state.artList
      }
    },
    felder: {
      get(){
        return this.$store.state.felder
      }
    },
    compareFelder: {
      get(){
        let preFelder = this.$store.state.felder;

        preFelder = Object.values(preFelder).filter(function(preFeld){
          // console.log(preFeld)
          if(!preFeld.comparing) return false
          return preFeld
        })

        return preFelder
      }
    },
    filterFelder: {
      get(){
        // console.log(this.$store.state.felder)
        const filterFelder = lodash.reduce(this.felder, function(obj, param){
            if(param.Filter !== 0) obj[param.Key] = param
            return obj
        }, {})
        // console.log(filterFelder)
        return filterFelder
      }
    },
    sorting: {
      get(){
        return {
          Key: this.$store.state.sorting.Key,
          order: this.$store.state.sorting.order
        }
      },
      set(sorting){
        this.$store.commit('updateSorting', sorting)
      }

    },
    chosenCats: {
      get(){
        if(this.quizWorkbook !== null){
          const sheetName = this.quizWorkbook.SheetNames[0] //Fragebogenliste
          /* DO SOMETHING WITH workbook HERE */
          // console.log(workbook);
          const fragebogen = this.quizWorkbook.Sheets[sheetName];
          const fragebogenjson = XLSX.utils.sheet_to_json(fragebogen)
          // delete fragebogenjson[0]["Kategorie"]
          const kategorien = Object.keys(fragebogenjson[0]).splice(1) //["Grünlandmischung", "Ackerfuttermischung", "Zwischenfruchtmischungen"] => Möglichkeiten für die 1. Frage
          return kategorien
        }
        else return false
      }
    },
    chosenCatProds: {
      get(){
        if(this.quizWorkbook !== null){
          const sheetName = this.quizWorkbook.SheetNames[0] //Fragebogenliste
          /* DO SOMETHING WITH workbook HERE */
          // console.log(workbook);
          const fragebogen = this.quizWorkbook.Sheets[sheetName];
          const fragebogenjson = XLSX.utils.sheet_to_json(fragebogen)
          // delete fragebogenjson[0]["Kategorie"]
          const kategorien = Object.keys(fragebogenjson[0]).splice(1) //["Grünlandmischung", "Ackerfuttermischung", "Zwischenfruchtmischungen"] => Möglichkeiten für die 1. Frage
          if(kategorien.includes(this.chosenCat)){
            const productSheetName = this.quizWorkbook.SheetNames[kategorien.indexOf(this.chosenCat) + 1] //Arbeitsblatt Name der gewählten Kategorie
            // console.log(this.quizWorkbook.Sheets[productSheetName])
            let sheetToJson = XLSX.utils.sheet_to_json(this.quizWorkbook.Sheets[productSheetName])
            if(this.quizAnswers.length > 0){
              this.quizAnswers.forEach(function(quizAnswer, quizId){
                if(quizAnswer !== "egal")
                sheetToJson = sheetToJson.filter(function(singleProd){
                  if(singleProd["Antwort " + (quizId + 1)] == quizAnswer) return singleProd
                })
              })
            }
            // console.log(sheetToJson)
            return sheetToJson
          }
          else return false
        }
        else return false

      }


    },
    chosenCatProps: {
      get(){
        let chosenProps
        if(this.chosenCatProds.length > 0)
        chosenProps = Object.keys(this.chosenCatProds[0]).filter(function(singleProp){
          if(!singleProp.includes('Kategorie') && !singleProp.includes('Antwort ')) return singleProp
        })
        else chosenProps = []
        return chosenProps
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch('init')
    // this.$store.dispatch('init').then(() => {
    //   const asdArr = [
    //     "Test 1",
    //     "Test 10",
    //     undefined,
    //     "Test 2",
    //     "Test 4",
    //     "Test 5",
    //     undefined,
    //     "Test 11",
    //     undefined,
    //     "Test 3",
    //     "Test 8",
    //     "Test 6",
    //     undefined,
    //     "Test 9",
    //     "Test 7",
    //     undefined,
    //   ]
    //   asdArr.sort(function(a, b){
    //     return (a > b) ? 1 : -1
    //   })
    //   console.log(asdArr);
    // })
  },
  created(){
    this.previewStaticFiles()
    // console.log(this.chosenCatProds)
    
        // axios.post('https://portal.stroetmann.de/kupoapi/kuposaat/getSI_threads.ashx', { "artenschluessel": [901], "sessionkey": "", "username": "0" })
        // .then(response => {
        //   console.log(response)
        // })

  }
}
</script>